
            @import "@/assets/sass/vars.portal.scss";
          























































































































































































































































.w-15 {
    width: 15% !important;
}
